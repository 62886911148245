import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   TooltipDirective,
   LoadingBarService,
} from "lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PoDeliveryDate } from "src/app/purchasing/pos/poDeliveryDateElement/poDeliveryDate.element.component";
import { PoItemListShort } from "src/app/purchasing/pos/poItemListShortElement/poItemListShort.element.component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderWorkflow } from "src/app/purchasing/types/purchase-order-workflow.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

type AugmentedPurchaseOrder = PurchaseOrder & {
   total: number | undefined;
   vendorNameStr: string | undefined;
   dateDisplay: Date | undefined;
   requestedUser: string | undefined;
   displayName: string | undefined;
   poNumberForDisplay: string | undefined;
};

@Component({
   selector: "po-list-item",
   templateUrl: "./poListItem.element.component.html",
   styleUrls: ["./poListItem.element.component.scss"],
   standalone: true,
   imports: [
      IconComponent,
      LimbleHtmlDirective,
      DropdownComponent,
      MinimalIconButtonComponent,
      DropdownItemComponent,
      DropdownDividerComponent,
      PoItemListShort,
      TooltipDirective,
      PoDeliveryDate,
      BetterCurrencyPipe,
      BetterDatePipe,
   ],
})
export class PoListItem implements OnInit, OnDestroy {
   @Input() public purchaseOrderID: number | undefined;
   @Input() public searchHints: LimbleMap<number, string> | undefined;
   @Input() public dataLogSection: string | undefined;

   public CID;
   public currencySymbol;
   public superUser;
   public augmentedPurchaseOrder: AugmentedPurchaseOrder | undefined;
   public openPOWatchVarSub;
   public openPRWatchVarSub;
   public vendorWatchVarSub;
   public InitialLoadCheck = 0;
   private purchaseOrderWorkflows: Array<PurchaseOrderWorkflow> | undefined;
   protected currentState: PurchaseOrderCurrentState | undefined;
   protected dataLogOptions: DataLogEventDefinition = {};

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageUser = inject(ManageUser);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.dataLogOptions.vendorInitiateLabel = this.dataLogSection
         ? `${this.dataLogSection}-initiateAVendor`
         : undefined;
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      ); //if they can manage roles they must be a super user
      this.openPOWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.watchData();
         },
      );
      this.openPRWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            this.watchData();
         },
      );
      this.vendorWatchVarSub = this.manageObservables.setSubscription(
         "vendorsWatchVar",
         () => {
            this.watchData();
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.openPOWatchVarSub,
         this.openPRWatchVarSub,
         this.vendorWatchVarSub,
      ]);
   }

   watchData = () => {
      if (this.purchaseOrderID === undefined) return;
      if (this.InitialLoadCheck < 2) {
         this.InitialLoadCheck++;
         return;
      }
      const purchaseOrder = this.managePO.getPurchaseOrder(this.purchaseOrderID);
      if (purchaseOrder === undefined) return;
      const vendor = purchaseOrder.vendorID
         ? this.manageVendor.getVendor(purchaseOrder.vendorID)
         : undefined;
      const totals = this.managePO.getPurchaseOrderCostTotals(this.purchaseOrderID);
      this.augmentedPurchaseOrder = {
         ...purchaseOrder,
         total: totals?.total,
         vendorNameStr: vendor?.vendorName ?? "",
         dateDisplay: purchaseOrder.date
            ? new Date(purchaseOrder.date * 1000)
            : undefined,
         requestedUser: purchaseOrder.requestedByUserID
            ? this.manageUser.getUserFullName(purchaseOrder.requestedByUserID)
            : undefined,
         displayName: this.managePO.getPurchasingAssignmentName({
            userID: purchaseOrder.userID,
            profileID: purchaseOrder.profileID,
         }),
         poNumberForDisplay: this.managePO.getPurchaseOrderNumberForDisplay(
            purchaseOrder.poID,
         )?.poNumberForDisplay,
      };
      this.currentState = this.managePO.getPurchaseOrderCurrentState(
         this.purchaseOrderID,
      );
   };

   protected popPurchaseOrder(purchaseOrder: AugmentedPurchaseOrder): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: purchaseOrder.poID },
         },
      };
   }

   protected async pickVendor(): Promise<void> {
      if (this.augmentedPurchaseOrder === undefined) return;

      const currentState = this.managePO.getPurchaseOrderCurrentState(
         this.augmentedPurchaseOrder.poID,
         this.purchaseOrderWorkflows,
      );

      if (
         currentState !== undefined &&
         this.checkPurchaseOrderEditable(currentState) === false
      ) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         //on rare circumstances we need to change the editable function of picking vendor
         return;
      }

      if (
         this.augmentedPurchaseOrder.locationID &&
         !this.credService.isAuthorized(
            this.augmentedPurchaseOrder.locationID,
            this.credService.Permissions.ChangePOVendor,
         )
      ) {
         this.alertService.addAlert(this.lang().cred146Fail, "warning", 10000);
         return;
      }

      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PleasePickWhichVendorYouWouldLikeToAddAPOFor,
            title: this.lang().PickVendors,
            data: {
               singleLocation: 0,
               selectOne: true,
               restrictToCred: false,
               iDontKnowOption: false,
               locationID: this.augmentedPurchaseOrder.locationID,
            },
         },
      };

      const vendorIDArray = await instance.result;
      const vendor = this.manageVendor.getVendor(vendorIDArray[0]);
      if (!vendor || !this.augmentedPurchaseOrder) return;

      //ok they picked a vendor let's now update everything we need to..
      const answer = await this.managePO.updateVendor(
         this.augmentedPurchaseOrder.poID,
         vendor.vendorID,
      );

      if (answer?.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   popVendor = (vendorID) => {
      if (vendorID == 0) {
         return;
      }

      const vendor = this.manageVendor.getVendor(vendorID);
      if (!vendor) {
         return;
      }

      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   protected async deletePO(): Promise<void> {
      if (!this.augmentedPurchaseOrder?.locationID) return;
      //we need different logic to see if they can delete the PO or not

      if (
         !this.credService.isAuthorized(
            this.augmentedPurchaseOrder.locationID,
            this.credService.Permissions.DeletePO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred145Fail, "danger", 10000);
         return;
      }

      //if there are items received then we can't allow it
      let foundTransaction = false;
      for (const purchaseOrderItemID of this.augmentedPurchaseOrder.poItemIDs) {
         const purchaseOrderItem =
            this.managePO.getPurchaseOrderItem(purchaseOrderItemID);
         if (!purchaseOrderItem) return;

         if (
            purchaseOrderItem.transactionIDs !== undefined &&
            purchaseOrderItem.transactionIDs.length > 0
         ) {
            foundTransaction = true;
         }
      }

      if (this.superUser == true) {
         //super users can always delete
      } else if (this.augmentedPurchaseOrder.state == 0) {
         //during setup anyone can delete it if they have the credential
      } else if (foundTransaction) {
         this.alertService.addAlert(
            this.lang().WhoopsYouCantDeleteAPOOnceItHasHadItemsReceived,
            "warning",
            10000,
         );
         return;
      }
      let msg = this.lang().DeletePOMsg;

      if (foundTransaction) {
         msg += `<br /><br /><b class="red-color">${this.lang().DeletePODeletingTransactionMsg}</b>`;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: msg,
            title: this.lang().DeletePO,
         },
      };

      const result = await instance.result;

      if (result !== 1 || this.augmentedPurchaseOrder === undefined) return;

      const answer = await this.managePO.deletePurchaseOrder(
         this.augmentedPurchaseOrder.poID,
      );
      if (answer === true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected async setReminder(): Promise<void> {
      if (this.augmentedPurchaseOrder === undefined) return;
      const instance = this.modalService.open(EmailTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().HaveUsSendYouAnEmailAtThisTime,
            title: this.lang().SetAReminder,
            data: {
               emailTo: this.manageUser.getCurrentUser().userInfo.userEmail,
               emailSubject: `${this.lang().ReminderForPurchaseOrder} ${this.augmentedPurchaseOrder.poNumber}`,
               emailMessage: this.lang().ThisPORequiresYourAttention,
               pickEmployees: false, //if we need to turn this on for po we need to do it slightly different
               checklistID: false,
               onSubmit: "returnData",
               pickDate: true,
            },
         },
         backdrop: "static",
         keyboard: false,
      };

      const result = await instance.result;

      if (result === 0 || this.augmentedPurchaseOrder === undefined) return;

      const answer = await this.manageUser.setReminder(
         0,
         result.recipients,
         result.date,
         result.subject,
         result.message,
         0,
         this.augmentedPurchaseOrder.poID,
         0,
      );

      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async changePOAssignment(): Promise<void> {
      if (this.augmentedPurchaseOrder === undefined) return;

      if (
         this.purchaseOrderWorkflows === undefined ||
         this.purchaseOrderWorkflows.length === 0
      ) {
         await this.getWorkflows();
      }
      const editableInfo = this.managePO.getPurchaseOrderEditable(
         this.augmentedPurchaseOrder.poID,
         this.manageUser,
         this.credService,
         this.purchaseOrderWorkflows,
      );

      if (editableInfo?.editable === false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.augmentedPurchaseOrder.locationID,
            this.credService.Permissions.ChangePOAssignment,
         )
      ) {
         this.alertService.addAlert(this.lang().cred148Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().ChangePurchaseOrderAssignment,
               locationID: this.augmentedPurchaseOrder.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.augmentedPurchaseOrder.userID,
               defaultProfile: this.augmentedPurchaseOrder.profileID,
            },
         },
      };

      const result = await instance.result;

      if (result === 0 || this.augmentedPurchaseOrder === undefined) return;

      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });

      const answer = await this.managePO.changePurchaseOrderAssignment(
         result.userID,
         result.profileID,
         this.augmentedPurchaseOrder.poID,
         result.multiUsers,
         true,
      );

      this.loadingBarService.remove();

      if (answer !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
         return;
      }

      this.alertService.addAlert(this.lang().successMsg, "success", 2000);
   }

   private async getWorkflows(): Promise<void> {
      if (this.augmentedPurchaseOrder === undefined) return;
      const answer = await this.managePO.getPurchaseOrderWorkflows(
         this.augmentedPurchaseOrder.poID,
      );
      if (answer.data.poWorkflows) {
         this.purchaseOrderWorkflows = answer.data.poWorkflows;
      }
   }

   private checkPurchaseOrderEditable(currentState: PurchaseOrderCurrentState): boolean {
      if (!this.augmentedPurchaseOrder) return false;
      if (
         currentState?.budgetWorkflowID !== undefined &&
         this.augmentedPurchaseOrder.vendorID == 0 &&
         this.managePO.getBudgetWorkflow(currentState.budgetWorkflowID)?.allowPR !== 1
      ) {
         return false;
      }
      if (
         this.managePO.getPurchaseOrderEditable(
            this.augmentedPurchaseOrder.poID,
            this.manageUser,
            this.credService,
         )?.editable === false
      ) {
         return false;
      }
      return true;
   }
}
